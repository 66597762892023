@import '../../../../_styles/colors';
@import '../../../../_styles/constants';

.calculator {
  width: 30rem;
  max-width: 30rem;
  background-color: $grey-dark;
  border-radius: $border-radius;
  padding: 2rem;
  box-shadow: 0.2rem 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.4);
  > :not(:first-child) {
    margin-top: 2rem;
  }
}

.calculator-line {
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 1rem;
  }
  > :not(:first-child) {
    margin-left: 1rem;
  }

  button {
    padding: 0.8rem;
    flex: 1;
    outline: none;
    font-family: Tahoma, Arial, Helvetica, sans-serif;
    color: white;
    text-align: center;
    background: $grey-dark2;
    border-radius: $border-radius;
    border: none;
    font-size: 2rem;
    cursor: pointer;

    &.non-number {
      background: $grey;
      color: $grey-dark2;
    }

    &:active {
      background: $text-color;
      color: white;
    }
  }
}

.calculator-button {
  > .button {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  > * {
    width: 100%;
  }
}

.calculator-display {
  flex: 1;
  height: 5rem;
  padding: 0 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 2px solid $grey;

  &.no-value {
    justify-content: center;
  }

  background-color: $grey-light;
  &.show-change {
    background-color: pink;
  }

  .value {
    font-size: 2.6rem;
    font-weight: bold;
    color: $text-color;
  }

  .placeholder {
    font-size: 2rem;
    color: $grey-dark;
    cursor: default;
  }
}
