@import '../../../../_styles/colors';
@import '../../../../_styles/constants';

$logo-height: 2.5rem;

.menu-logo {
  position: fixed;
  top: calc((#{$navbar-height} - #{$logo-height}) / 2);
  left: 1.8rem;
  vertical-align: middle;
  z-index: 3;

  svg {
    height: $logo-height;
    width: auto;
    g {
      fill: $text-color;
    }
  }
}

.main-menu {
  height: $navbar-height;
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: $white;
  box-shadow: 0 1px 0 0 rgba(191, 191, 191, 0.5);
  padding: 0 2rem 0 0;
  color: $text-color;

  nav {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 0 2rem 0 $sidebar-width;
    position: relative;
    height: 100%;

    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 70%;
      top: 15%;
      background-color: $text-color;
      position: absolute;
      right: 2rem;
      opacity: 0.2;
    }

    > div {
      display: flex;
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      padding: 2.2rem 2.4rem;
      font-size: 1.8rem;
      font-weight: bold;
      color: $text-color;

      > :not(:first-child) {
        margin-left: 1rem;
      }
    }

    div.profile {
      padding: 2.2rem 2.4rem;
      font-size: 1.8rem;
      color: $text-color;
    }
  }

  button {
    color: $text-color;
  }
}
