@import '../../../_styles/colors';

$padding: 2rem;

.orders-unfinished-list {
  .item {
    height: 8rem;
    padding: 0 $padding;
    display: flex;
    align-items: center;

    &:first-child {
      border-top: 1px solid $grey;
    }

    &:nth-child(even) {
      background-color: $grey-light;
    }

    .item-name {
      font-weight: bold;
    }

    .item-date {
      font-size: 1.6rem;
      font-style: italic;
      color: $grey-dark2;
      text-transform: lowercase;
    }

    .item-price {
      flex: 1;
      text-align: right;
    }

    .item-button {
      padding-left: $padding;
      border-left: 1px solid $grey;
    }

    > :not(:first-child) {
      margin-left: $padding;
    }
  }
}
