$font-family: Tahoma, Arial, Helvetica, sans-serif;

$navbar-height: 6rem;
$input-height: 4rem;
$action-bar-height: 7.5rem;
$input-icon-height: 2.2rem;
$input-label-bottom-margin: 0.5rem;
$sidebar-width: 30rem;
$sidebar-padding: 2rem;
$border-radius: 0.6rem;
