@import '../../_styles/colors';
@import '../../_styles/constants';

$padding: 1.1rem 1.5rem;
$icon-height: 2rem;

.ui.button {
  padding: $padding;
  font-size: 2rem;
  font-weight: bold;
  border-radius: $border-radius;
}

button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > :not(:first-child) {
    margin-left: 0.8rem;
  }

  i,
  svg {
    width: $icon-height;
    height: $icon-height;
  }
}

@mixin setColorTo($color) {
  color: $color;
  &:hover,
  &:focus {
    outline: transparent;
    color: darkenOnHover($color);
  }
}

button.as-text {
  padding: $padding;
  background: transparent;
  border: none;
  font-size: 1.8rem;
  font-weight: bold;
  height: 100%;
  text-align: center;
  cursor: pointer;
  font-family: $font-family;
  white-space: nowrap;

  @include setColorTo($grey);
  &.primary {
    @include setColorTo($primary-color);
  }
  &.negative {
    @include setColorTo($secondary-color);
  }

  &.loading {
    color: transparent;
    &:hover,
    &:focus {
      color: transparent;
    }

    .loader-wrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0;
    }
  }
}
