@import '../../_styles/constants';

.order-checkout {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2.2rem;

    > span {
      text-align: center;
    }
  }

  .content {
    min-width: 70rem;
    margin: 5rem auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > * {
      flex: 1;
    }

    .buttons-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      > * {
        width: 26rem;
      }

      > *:not(:first-child) {
        margin-top: 2rem;
      }
    }
  }

  .buttons {
    width: 100%;
    margin-top: 2rem;

    > * {
      display: block;
    }

    > *:not(:first-child) {
      margin-top: 2rem;
    }
  }
}
