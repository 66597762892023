@import '../../_styles/colors';

.base-modal {
  .close-icon {
    right: 2rem;
    top: 2rem;
    position: absolute;
    color: $grey;
  }

  .actions {
    margin: 0;
    display: flex;
    justify-content: flex-end;
  }

  .content {
    margin: 0;
    > :not(:first-child) {
      margin-top: 1.5rem;
    }
  }

  .content-description {
    color: $grey-dark;
    padding-bottom: 1.5rem;
  }

  form {
    .header {
      padding: 3.4rem 3.2rem;
      font-size: 2rem;
      font-weight: 700;
    }
    .content {
      padding: 0 3.2rem;
    }
    .actions {
      padding: 4rem 3.2rem 2.4rem;
    }
  }
}
