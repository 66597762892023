@import './colors';
@import './constants';

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: $sidebar-width;
  background-color: $grey-light;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.15);
  z-index: 2;
  padding-top: calc(#{$navbar-height} + 2rem);

  .bottom {
    flex: 0;
    padding: 2rem;
    text-align: center;
    :not(:first-child) {
      padding-top: 1rem;
    }

    button {
      width: 100%;
    }
  }
}
