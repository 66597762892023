.checkbox-group {
  > .input-wrapper + .input-wrapper {
    margin-top: 0.75rem;
  }

  &.horizontal {
    display: flex;
    align-items: center;

    > :not(:first-child) {
      margin-left: 4rem;
    }

    > .input-wrapper {
      margin-top: 0;
    }
  }
}
