@import '../../_styles/colors';

.order-finished {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2.2rem;
    padding-top: 10rem;
  }

  .icon {
    color: $primary-color;
    margin-bottom: 1.2rem;
  }

  .text {
    margin-bottom: 6rem;
  }
}
