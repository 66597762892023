@import '../../_styles/colors';
@import '../../_styles/constants';

.orders-unfinished {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2.2rem;
  }

  .content {
    margin-top: 5rem;
    width: 100%;

    .no-results {
      color: $grey-dark2;
      margin-top: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > :last-child {
        margin-top: 2rem;
      }
    }
  }

  .sidebar.sidebar-orders-unfinished {
    display: flex;
    flex-direction: column;
    .explanation {
      flex: 1;
      padding: $sidebar-padding;
      color: $grey-dark2;
      > p:not(:first-child) {
        margin-top: 2rem;
      }
    }
    .bottom {
      flex: 0;
    }
  }
}
