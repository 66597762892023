@import '../../../_styles/colors';

.unauthorized-layout {
  height: 100vh;
  margin-top: -5.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg.logo {
    width: 20rem;
    height: auto;

    g {
      fill: $text-color;
    }
  }

  form {
    width: 38rem;
    max-width: 38rem;
  }
}
