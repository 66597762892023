.login {
  .input-fields {
    :not(:first-child) {
      margin-top: 1rem;
    }
    input {
      text-align: center;
    }
  }
  .actions {
    justify-content: center;
    > * {
      width: 100%;
    }
  }
}
