@import './_styles/breakpoints';
@import './_styles/colors';
@import './_styles/constants';
@import './_styles/form';
@import './_styles/sidebar';
@import './_styles/reset';

html {
  // This value defines the value of '1rem'
  font-size: 10px;
}

html,
body {
  height: auto;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
}

button {
  color: $text-color;
  font-family: $font-family;
  font: unset;
}

h1 {
  font-size: 3.2rem;
  margin-top: 0;
  margin-bottom: 2rem;
}

h3 {
  font-size: 2.2rem;
  margin-top: 0;
  margin-bottom: 1.4rem;
}

.container {
  margin-top: $navbar-height;
  margin-left: $sidebar-width;
  padding: 2rem;
}

body {
  font-size: 1.8rem;
}

main {
  margin-top: 6rem;
}

a {
  color: $primary-color;
  &:hover {
    color: darkenOnHover($primary-color);
  }
}

button.plain {
  border: 0;
  background: transparent;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  outline: 0;
}
