@import '../../../_styles/colors';
@import '../../../_styles/constants';

.sidebar-checkout {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  .order-overview {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    h3 {
      flex: 0;
      padding: 0 $sidebar-padding;
    }

    .products-wrapper {
      overflow-y: scroll;

      .item-wrapper {
        &:not(:first-child):before {
          height: 1px;
          width: calc(100% - 4rem);
          margin: 1rem auto;
          background-color: $grey;
          display: block;
          content: '';
        }

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 $sidebar-padding;

          flex: 0;
          .name {
            flex: 1;
            padding: 0 1.7rem;
          }

          .small-text {
            font-size: 1.4rem;
          }
        }
      }
    }

    .products-amount {
      flex: 0;
      color: $grey-dark;
      text-align: center;
      padding: 0 $sidebar-padding;
      padding-top: $sidebar-padding;
    }
  }

  .bottom.readonly {
    .button-wrapper {
      visibility: hidden;
    }
  }
}
