$primary-color: #00cec7;
$secondary-color: #f36;
$text-color: #4f4f4f;
$disabled-color: #eeeeee;
$placeholder-color: #b8bcbf;
$disabled-background-color: #eeeeee;
$error-background-color: #fff6f6;
$error-border-color: #e0b4b4;
$error-placeholder-color: #e7bdbc;

$shadow: rgba(34, 36, 38, 0.15);
$white-transparent: rgba(255, 255, 255, 0.2);

$grey: #e0e0e0;
$grey-dark2: #828282;
$grey-dark: #c8c8c8;
$grey-light: #f2f2f2;
$red: #e45f55;
$red-light: #fceaea;
$green: #24c289;
$orange: #ed8f21;
$orange: orange;
$white: #ffffff;

@function darkenOnHover($color) {
  @return darken($color, 15%);
}
